<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div
        class="
          public-areas public-area-user-list
          margin-bottom-10 margin-top-10
        "
      >
        <div class="weui-cells margin-top-0">
          <div class="weui-cell" href="javascript:;">
            <div class="weui-cell__hd">
              <label class="weui-label">昵称</label>
            </div>
            <div class="weui-cell__bd">
              <p>
                <input
                  class="weui-input"
                  id="nickname"
                  type="text"
                  maxlength="12"
                  placeholder="请输入昵称"
                  v-model="name"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="public-area-submit-box">
        <div class="weui-cells margin-top-0">
          <div class="weui-cell">
            <div class="weui-cell__bd">
              <input
                type="hidden"
                name="action"
                id="action"
                value="edit_nickname"
              />
              <a
                @click="updateInfo"
                class="weui-btn weui-btn_primary public-btn-user-edit"
                >保存</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
  </div>
</template>

<script>
import tabbar from '../components/tabbar/index.vue'
import wxauth from '../mixin/wxauth'
import { setNonsensitiveInfo } from '../api/index'
export default {
  mixins: [wxauth],
  name: "editNickname",
  components: {
    tabbar
  },
  data () {
    return {
      name: ''
    }
  },
  created () {
    if (localStorage.getItem('SURVEY_nickName')) {
      this.name = localStorage.getItem('SURVEY_nickName')
    }
  },
  methods: {

    async doSetNonsensitiveInfo () {
      let params = {
        param: {
          nickName: this.name
        }
      }
      let res = {}
      try {
        res = await setNonsensitiveInfo(params)
        this.$toast.loading('更新中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.loading('更新成功', { duration: 0 });
          setTimeout(() => {
            this.$router.back()
          }, 2000)
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    updateInfo () {
      if (!this.name) {
        this.$toast.info('昵称不能为空')
        return false
      }
      this.doSetNonsensitiveInfo()
    }
  }
};
</script>

<style lang="css" scoped>
.public-container{
  margin: unset;
}
.weui-btn.public-btn-user-edit{
  padding: unset;
}
</style>
